<template>
  <div>
    <page-banner>
      <template #banner-txt>
        聯絡我們
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>

    <div class="common-bg padding-50">
      <div class="container">
        <div class="contact-info">
          <ul>
            <li>
              <div class="contact-icon">
                 <img src="@/assets/img/icon_slice1.png">
              </div>
              <h2>聯絡方式</h2>
              <div class="contact-txt">
                <p>
                  <i class="layui-icon layui-icon-ok"></i>電話： {{ tel }}
                </p>
                <p>
                  <i class="layui-icon layui-icon-ok"></i>傳真： {{ fax }}
               </p>
                <p>
                  <i class="layui-icon layui-icon-ok"></i>電郵： {{ email }}
                </p>
             </div>
            </li>
            <li>
              <div class="contact-icon">
                <img src="@/assets/img/icon_slice2.png">
              </div>
              <h2>聯絡地址</h2>
              <div class="contact-txt">
                <div class="contact-txt-3">
                  <i class="layui-icon layui-icon-ok"></i>
                  <div>
                    <span>地址： {{ address }} </span>
                  </div>
                </div>
               </div>
            </li>
            <li>
              <div class="contact-icon">
                <img src="@/assets/img/icon_slice3.png">
              </div>
              <h2>開放時間</h2>
              <div class="contact-txt">

                <div class="contact-txt-3">
                  <i class="layui-icon layui-icon-ok"></i>

                  <div>
                       <div v-for="item in opening_hours" :key="item" class="time-area">
                        <span>{{ item.key }}</span>
                        <!--   转化\n\r  -->
                        <div v-html="item.value"  style="white-space: pre-wrap;" ></div>
                       </div>
                  </div>
                 </div>
               </div>
            </li>
          </ul>
        </div>

        <div class="contact-map padding-top-50">
          <iframe :src="google_link" :key="google_link" style="border:0;" allowfullscreen="" loading="eager" ></iframe>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import pageBanner from "@/components/PageBanner";

import {getContactInfo} from "@/network/home";

export default {
  name: "Contact",
  components: {
    pageBanner,
  },
  data() {
    return{
      banner:{},
      tel:{},
      fax:{},
      email:{},
      address:{},
      opening_hours:[],
      google_link:{},
    }
  },
  created() {
    this.getContactInfo()
  },
  methods: {
    getContactInfo(){
      getContactInfo().then(res =>{//.then可以拿到数据
         // console.log(res.data.data)
        this.banner = res.data.data.banner;
        this.tel = res.data.data.tel;
        this.fax = res.data.data.fax;
        this.email = res.data.data.email;
        this.address = res.data.data.address;
        this.opening_hours = res.data.data.opening_hours;
        this.google_link = res.data.data.google_link;
      })
    }
  }

}
</script>

<style scoped>

.contact-info ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-info ul li{
  flex: 0 0 30%;
  margin: 0 1.5%;
  padding: 60px 30px;
  position: relative;
  color: #fff;
  background: url("~@/assets/img/contact_bg1.png") no-repeat;
  background-size: cover;
}

.contact-info ul li:nth-child(1){
   margin-left: 0;
}
.contact-info ul li:nth-child(3){
  margin-right:0;
}


.contact-icon{
  width: 88px;
  position: absolute;
  top: 0;
  right: 0;
}
.contact-txt{

  padding-top: 20px;
}

.contact-txt i{
  margin-right: 10px;
}

.contact-info ul li h2{
  text-align: center;
}

.contact-map iframe{
  width: 100%;
  height: 450px;
}

.contact-txt p{
  display: flex;
  align-items: center;
  display: inline-block;
  width: 100%;
  padding: 5px 0;
}

.contact-txt-3{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.contact-txt span{
  display: block;
  width: 100%;
}

.time-area{
  margin-bottom: 10px;
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .contact-info ul li{
    flex: 0 0 100%;
    margin: 0;
    padding: 30px;
    margin: 20px 0;
  }

  .contact-icon{
    width: 44px;
  }

  .contact-txt{
     padding-top: 0;
  }

  .contact-info ul li h2{
    font-size: 18px;
  }

}




</style>